$atmmachineprices-pink: #f59aac;
$atmmachineprices-yellow: #ffe46e;
$atmmachineprices-blue-light: #e9f9ff;
$atmmachineprices-orange: #f38620;
$atmmachineprices-grey: #7d7d7d;
$atmmachineprices-grey-light: #f3f3f3;

:export {
    pink: $atmmachineprices-pink;
    blueLight: $atmmachineprices-blue-light;
    orange: $atmmachineprices-orange;
    grey: $atmmachineprices-grey;
    greyLight: $atmmachineprices-grey-light;
}
